import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Visibility } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Details({ item, amount }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="View Details">
        <Button
          variant="text"
          onClick={handleClickOpen}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: 0,
            color: "ButtonFace",
          }}
        >
          Subscribe to {item.title} <Visibility />
        </Button>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <div>{item.title} </div>
          <div style={{ fontSize: 14 }}> {item.author}</div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {item.description.replace("{{amount}}", amount)}
          </Typography>
          <Typography gutterBottom>
            To subscribe, kindly choose your prefered option by clicking the
            button on the page.
          </Typography>
          <Typography gutterBottom>
            NB: Manual subscriptions are one time payment subscription options.
            You will have to manually renew the service after expiration. With
            autodebit, you do not have to perform a manual service renewal.
            Deductions will happen automatically ones there is enough balance in
            your wallet.
          </Typography>
          <Typography gutterBottom>
            With autodebit, you do not have to perform a manual service renewal.
            Deductions will happen automatically ones there is enough balance in
            your wallet.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Okay
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
