import useDigifish from "./useDigifish";
import digiDb from "../../utils/constants/digi_db.json";
import digiImg from "../../assets/images/digiBanner.png";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";

function Digifish() {
  useDigifish();

  return (
    <div className="digi-bkg">
      <div className="digi-main">
        <img
          src={`${digiImg}`}
          srcSet={`${digiImg}`}
          alt={digiDb[0].title}
          style={{ width: "60%", height: "60%" }}
          loading="lazy"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={35}
            sx={{
              color: green[500],
              position: "relative",
              left: "48.5%",
            }}
          />
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "40px",
              paddingTop: "85px",
            }}
          >
            loading...
          </p>
        </div>
      </div>
    </div>
  );
}

export default Digifish;
