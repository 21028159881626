import { useLocation, useParams } from "react-router-dom";
import imageData from "../../utils/constants/digi_db.json";
import { useContentFetch, useSubFetch } from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { findKeyword } from "../../utils/utilities";

export const useDigiSubLogic = () => {
  const { digikeyword } = useParams();
  const [params, setParams] = useState("");
  const [fetch, setFetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(30000);
  //
  useEffect(() => {
    document.title = "Digifish HTML5 Games - Demo";
  }, []);
  //
  const serviceInfo = findKeyword(imageData, digikeyword);
  const amount = serviceInfo[0]?.keyword
    .filter(
      (keyItem) =>
        keyItem?.keyword.toLocaleLowerCase() === digikeyword.toLocaleLowerCase()
    )
    .at(0).amount;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceResponse = {
    msisdn: searchParams.get("number"),
    otp: searchParams.get("otp"),
    expiry: searchParams.get("expiry"),
    message: searchParams.get("message"),
    key: searchParams.get("key"),
  };
  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      number: serviceResponse.msisdn,
    },
  });

  const onSubmit = (values, info) => {
    setParams(`${values.number}/${info.keyword}`);
    setFetch(true);
  };
  const { data, error, isError, isLoading, status } = useContentFetch(
    `content/?keyword=${digikeyword}&key=${serviceResponse?.key}&number=${serviceResponse?.msisdn}`,
    true
  );
  const {
    data: sdata,
    error: serror,
    isError: sisError,
    isLoading: sisLoading,
    status: sstatus,
  } = useSubFetch(`subscribe/${params}`, fetch);

  //
  const [fetches, fetchContent] = useState(false);
  useSubFetch(
    `/subscribe/status/${serviceResponse.msisdn}/${digikeyword}`,
    true,
    refetchInterval,
    true,
    (data) => {
      if (
        data?.data?.data.length !== 0 &&
        data?.data?.data[0]?.state !== "EXPIRED"
      ) {
        setRefetchInterval(false);
        fetchContent(true);
      }
    }
  );

  setTimeout(() => {
    setRefetchInterval(false);
  }, 120000);

  const {
    data: cdata,
    error: cerror,
    isError: cisError,
    isLoading: cisLoading,
    status: cstatus,
  } = useContentFetch(
    `content/?keyword=${digikeyword}&key=${serviceResponse?.key}&number=${serviceResponse?.msisdn}`,
    fetches
  );

  //
  return {
    digikeyword,
    serviceInfo: serviceInfo[0],
    serviceResponse,
    amount,
    submitfetchResponse: {
      sdata,
      serror,
      sisError,
      sisLoading,
      sstatus,
    },
    contentResponse: fetches
      ? {
          data,
          error,
          isError,
          isLoading,
          status,
          isPoolingDone: !!cdata || !!cerror,
        }
      : {
          data: cdata,
          error: cerror,
          isError: cisError,
          isLoading: cisLoading,
          status: cstatus,
          isPoolingDone: false,
        },
    handlers: { handleSubmit, handleClose, onSubmit },
    state: {
      register,
      errors,
      open,
      params,
    },
  };
};
