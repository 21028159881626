import { useMutation } from "react-query";
import {
  APISecuredAxios,
  SecuredSubsciptionAxios,
} from "../libs/axios/securedAxios";

const postUserData = ({ url, data, method }) => {
  return APISecuredAxios()({ method, url, data });
};
export const usePost = (onSuccess, onError) => {
  return useMutation(postUserData, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

const postWalapiUserData = ({ url, data, method }) => {
  return SecuredSubsciptionAxios()({ method, url, data });
};
export const useWalapiPost = (onSuccess, onError) => {
  return useMutation(postWalapiUserData, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
