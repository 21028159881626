import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import { CircularProgress, Typography, Box } from "@mui/material";
import Notification from "../Notification";
import useLoginLogic from "./useLoginLogic";
import { PasswordInput, TextInput } from "../Form/FormsInputs";

export default function LoginForm() {
  const { state, handlers, fetchResponse } = useLoginLogic();
  const { register, errors, open, notificationMessage } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose } = handlers;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      {Object.keys(errors).map((key, index) => (
        <div key={index++}>
          <Typography
            variant="caption"
            color={"red"}
            gutterBottom
            align="center"
          >
            {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${
              errors[key].message
            }`}
          </Typography>
        </div>
      ))}

      <TextInput
        register={register}
        name="username"
        label="Username*"
        error={errors?.username}
        autoFocus
      />
      <PasswordInput register={register} error={errors?.username} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={isLoading}
      >
        Login
      </Button>

      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: "absolute",
            top: "52.2%",
            left: "49%",
          }}
        />
      )}
      <Notification
        severity="error"
        message={notificationMessage}
        openNotification={open}
        handleClose={handleClose}
      />
    </Box>
  );
}
