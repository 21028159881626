import { useQuery } from "react-query";
import {
  APISecuredAxios,
  SecuredContentAxios,
  SecuredSubsciptionAxios,
} from "../libs/axios/securedAxios";

const fetchData = ({ queryKey }) => {
  if (Array.isArray(queryKey) && queryKey.length > 1) {
    const url = queryKey.join("/");
    return APISecuredAxios()({ method: "get", url });
  }
  return APISecuredAxios()({ method: "get", url: `${queryKey[0]}` });
};

export const useFetch = (key, enabled = false) => {
  return useQuery(key, fetchData, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: 30000,
    staleTime: 30000,
  });
};

//for content
const fetchContentData = ({ queryKey }) => {
  if (Array.isArray(queryKey) && queryKey.length > 1) {
    const url = queryKey.join("/");
    return SecuredContentAxios()({ method: "get", url });
  }
  return SecuredContentAxios()({ method: "get", url: `${queryKey[0]}` });
};

export const useContentFetch = (key, enabled = false) => {
  return useQuery(key, fetchContentData, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: 30000,
    staleTime: 30000,
  });
};

//for subscriptions

//for content
const fetchSubscriptionData = ({ queryKey }) => {
  if (Array.isArray(queryKey) && queryKey.length > 1) {
    const url = queryKey.join("/");
    return SecuredSubsciptionAxios()({ method: "get", url });
  }
  return SecuredSubsciptionAxios()({ method: "get", url: `${queryKey[0]}` });
};

export const useSubFetch = (
  key,
  enabled = false,
  refetchInterval = false,
  refetchIntervalInBackground = true,
  onSuccess = () => null
) => {
  return useQuery(key, fetchSubscriptionData, {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: 30000,
    staleTime: 30000,
    refetchInterval,
    refetchIntervalInBackground,
    onSuccess,
  });
};
