import { useState, useEffect } from "react";

export default function useService() {
  const [numCols, setNumCols] = useState(1);

  useEffect(() => {
    function handleResize() {
      // Update number of columns based on screen width
      if (window.innerWidth >= 768) {
        // Tablet and desktop mode
        setNumCols(2);
      } else {
        // Mobile mode
        setNumCols(1);
      }
    }

    // Initial call to handleResize
    handleResize();

    // Add event listener to update on window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return { numCols };
}
