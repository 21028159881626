import { createContext, useContext, useState } from "react";
import moment from "moment";

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

function UserContextProvider({ children }) {
  const [from, setFromValue] = useState(moment(new Date()));
  const [to, setToValue] = useState(moment(new Date()));

  const handleFromDateChange = (newValue) => {
    setFromValue(newValue);
  };
  const handleToDateChange = (newValue) => {
    setToValue(newValue);
  };

  return (
    <UserContext.Provider
      value={{ handleFromDateChange, handleToDateChange, to, from }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
