import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { getKeyCookie } from "../../utils/auth";
import itemData from "../../utils/constants/db.json";
import Details from "../../components/Modals/Details/Details";
import CustomButtonGroup from "../../components/ButtonGroup/CustomButtonGroup";
import useService from "./useService";

function Service() {
  const access = getKeyCookie();
  const { numCols } = useService();
  const colors = ["primary", "secondary", "success", "info", "warning"];

  return (
    <ImageList cols={numCols}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <div
            style={{
              position: "absolute",
              top: "0px",
              background: "black",
              padding: "5px",
            }}
          >
            <Details
              item={item}
              amount={
                item.keyword
                  .filter(
                    (keyItem) => keyItem.type.toLocaleLowerCase() === "daily"
                  )
                  .at(0).amount
              }
            />
          </div>
          <img
            src={`${item.img}?w=248&h=120&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&h=120&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />

          <ImageListItemBar
            actionIcon={
              <div style={{ display: "flex" }}>
                {item.keyword.map((item, id) => {
                  return (
                    <CustomButtonGroup
                      item={item}
                      key={id++}
                      access={access}
                      color={colors[id]}
                    />
                  );
                })}
              </div>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default Service;
