import { Button, CircularProgress } from "@mui/material";
import { useContent } from "./useContent";
import { green } from "@mui/material/colors";

function Content({ data, keyword, msisdn }) {
  const { logoutMsg, logout, handleUnsub, isLoading } = useContent(
    keyword,
    msisdn
  );
  return (
    <div>
      {data.length > 0 ? (
        <div>
          <h2>Content for {keyword}:</h2>
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <div>
                  <strong>{item.title}:</strong> {item.message}
                </div>
                <div>
                  <strong>Status:</strong> {item.status || "N/A"}
                </div>
                <div>
                  <strong>Time Created:</strong> {item.time_created}
                </div>
                <div>
                  <strong>Time Updated:</strong> {item.time_updated}
                </div>
                <p style={{ marginTop: 40 }} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <p>No content found for {keyword}</p>
        </div>
      )}
      <Button
        onClick={handleUnsub}
        variant="contained"
        color="secondary"
        disabled={isLoading}
      >
        {isLoading && (
          <CircularProgress
            size={35}
            sx={{
              color: green[500],
              position: "relative",
              left: "48.5%",
            }}
          />
        )}
        Unsubscribe
      </Button>
      {logout && <p>{logoutMsg}</p>}
    </div>
  );
}

export default Content;
