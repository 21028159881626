import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { URLS } from "../../utils/constants/urls";

const options = ["Manual", "Auto Debit"];

export default function CustomButtonGroup({ item, access, color }) {
  const url = URLS.CALLBACKURL;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (_event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        color={color}
        style={{ margin: 5 }}
        size="small"
      >
        <Button>
          <Link
            to={`http://nhe.nalovas.com/?key=${access}&keyword=${item.keyword}&url=${url}/subscribe/${item.keyword}&opt=${selectedIndex}`}
            style={{ color: "ButtonFace" }}
          >
            {item.type}{" "}
            {selectedIndex === 1
              ? `${options[selectedIndex].charAt(0)}. ${options[
                  selectedIndex
                ].charAt(5)}`
              : `${options[selectedIndex].charAt(0)}.`}
          </Link>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <Link
                      key={option}
                      to={`http://nhe.nalovas.com/?key=${access}&keyword=${item.keyword}&url=${url}/subscribe/${item.keyword}&opt=${index}`}
                      style={{ color: "black" }}
                    >
                      <MenuItem
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        id={index}
                      >
                        {option}
                      </MenuItem>
                    </Link>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
