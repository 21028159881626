function OTP({ message, serviceInfo, otp, expiry, msisdn, amount }) {
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <h1>Subscribing to {serviceInfo?.title}</h1>
      <p>{serviceInfo?.description.replace("{{amount}}", amount)}</p>
      {message}
      <p>
        Dial{" "}
        <a href={`tel:${otp}`} style={{ textDecoration: "none" }}>
          <b>{otp}</b>
        </a>{" "}
        on {msisdn} to subscribe to {serviceInfo.title}
      </p>
      <strong>
        <div>
          <i>NB:</i>
        </div>
      </strong>
      <ul>
        <li>
          <i>USSD Code expires in {expiry}</i>
        </li>
        <li>
          <i>
            If {msisdn} is not the intended number, kindly connect mobile
            hotspot from the intended mobile number to use
          </i>
        </li>
      </ul>
    </div>
  );
}

export default OTP;
