import { TextField } from "@mui/material";
import classes from "./forms.module.css";

export function TextInput(props) {
  const { register, error, name, label, autoFocus } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 2,
          message: "Must be more than 2 characters",
        },
        pattern: {
          value: /^[a-zA-Z0-9@_\-\s]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      placeholder={name}
      label={label}
      autoComplete={name}
      helperText={error?.message}
      className={classes.form__input}
      autoFocus={autoFocus}
    />
  );
}

export function PasswordInput(props) {
  const { register, error } = props;
  return (
    <TextField
      {...register("password", {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 8,
          message: "Must be more than 8 characters",
        },
        pattern: {
          value: /^(?=.*[a-zA-Z])(?=.*\d)[!@#$%^&*+(){ }?></a-zA-Z\d]{8,}$/,
          message: "Must be alphanumeric with no unacceptable character(s)",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      label="Password*"
      type="password"
      helperText={error?.message}
      autoComplete="current-password"
      className={classes.form__input}
    />
  );
}

export function NumberInput(props) {
  const { register, error, name, label, placeholder } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 10,
          message: "Must be more than 10 characters",
        },
        pattern: {
          value: /^[0-9\s]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      label={label}
      helperText={error?.message}
      type="number"
      placeholder={placeholder}
      className={classes.form__input}
    />
  );
}
