import { useEffect, useState } from "react";
import { useWalapiPost } from "../../hooks/usePost";
import { removeCookie } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

export function useContent(keyword, msisdn) {
  const [enable, setEnable] = useState();
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();

  const logoutMsg = `You have successfully opted out from service with keyword ${keyword}`;
  //
  const onErrorCall = (error) => {
    setEnable(false);
    console.log({ error });
  };

  const onSuccessFulCall = (data) => {
    removeCookie();
    setLogout(true);
    setTimeout(() => {
      navigate("/", {
        replace: true,
      });
    }, 50000);
  };

  const handleUnsub = () => {
    setEnable(true);
  };
  //
  const { mutate, isLoading } = useWalapiPost(onSuccessFulCall, onErrorCall);

  useEffect(() => {
    if (enable) {
      mutate({
        url: `subscribe/unsub/${msisdn}/${keyword}`,
        method: "put",
        data: {},
      });
    }
  }, [enable, msisdn, keyword]);

  return { logoutMsg, logout, handleUnsub, isLoading };
}
