import AssessmentIcon from "@mui/icons-material/Assessment";

export const routes = {
  USER: [
    {
      id: "service",
      name: "Service",
      Icon: AssessmentIcon,
    },
  ],
};
