import { CircularProgress, Grid } from "@mui/material";
import { green } from "@mui/material/colors";
import { useSubscriberLogic } from "./useSubscribeLogic";
import SubscribeComponent from "../../components/Subscription/SubscribeComponent";
import Content from "../../components/Content/Content";
import OTP from "../../components/Subscription/OTP";

function Subscribe() {
  const {
    keyword,
    serviceInfo,
    serviceResponse,
    amount,
    submitfetchResponse,
    handlers,
    state,
    contentResponse,
  } = useSubscriberLogic();

  //
  const { register, errors } = state;
  const { sisLoading, sisError, sdata, serror, sstatus } = submitfetchResponse;

  const { handleSubmit, onSubmit } = handlers;
  //
  if (!serviceInfo?.keyword) {
    return (
      <div>
        <CircularProgress
          size={35}
          sx={{
            color: green[500],
            position: "absolute",
            left: "48.5%",
          }}
        />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px",
            paddingTop: "35px",
          }}
        >
          loading...
        </p>
      </div>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <img
          src={`${serviceInfo.img}`}
          srcSet={`${serviceInfo.img}`}
          alt={serviceInfo.title}
          style={{ width: "100%", height: "100%" }}
          loading="lazy"
        />
      </Grid>
      <Grid item md={6}>
        {!contentResponse.isPoolingDone ? (
          <OTP
            message={serviceResponse?.message}
            serviceInfo={serviceInfo}
            msisdn={serviceResponse?.msisdn}
            otp={serviceResponse?.otp}
            expiry={serviceResponse?.expiry}
            amount={amount}
          />
        ) : contentResponse.data?.data === "EXPIRED" ? (
          <SubscribeComponent
            register={register}
            errors={errors}
            serror={serror}
            sdata={sdata}
            sstatus={sstatus}
            sisLoading={sisLoading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            sisError={sisError}
            message={serviceResponse.message}
            serviceInfo={serviceInfo}
            keyword={keyword}
            msisdn={serviceResponse.msisdn}
            amount={amount}
          />
        ) : (
          <Content
            data={contentResponse.data?.data || []}
            keyword={keyword}
            msisdn={serviceResponse?.msisdn}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Subscribe;
