import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils/auth";

export const useLogoutLogic = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeCookie();
    navigate("/", {
      replace: true,
    });
  };

  const handleServicePage = () => {
    navigate("/service");
  };
  return { handleLogout, handleServicePage };
};
