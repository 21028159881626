import { getAuthCookie, getKeyCookie, isAuthenticated } from "./auth";

export function findKeyword(data, keyword) {
  const foundItems = [];

  data.forEach((item) => {
    item.keyword.forEach((kw) => {
      if (kw.keyword === keyword) {
        foundItems.push(item);
      }
    });
  });

  return foundItems;
}

export function isAuthTokenExpired() {
  const token = getAuthCookie();
  const accessKey = getKeyCookie();
  if (!isAuthenticated()) {
    return { status: true, auth: "Not set", accessKey: "Not set" };
  }
  // Decode the token to access expiration time (exp)
  try {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = decodedToken.exp;
    // Get current Unix timestamp in seconds
    const currentTime = Math.floor(Date.now() / 1000);
    // Check if expiration time is before current time
    // false if not expired|true if expired
    if (currentTime > expirationTime)
      return { status: true, auth: "Expired", accessKey: "Expired" };
    return { status: false, auth: token, accessKey };
  } catch (error) {
    // Consider returning true as a safety measure if decoding fails
    return { status: true, auth: "Error decoding token", accessKey: "Error" };
  }
}
