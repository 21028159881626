import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth";
import Header from "../Header/Header";

function PrivateRoutes() {
  if (!isAuthenticated()) return <Navigate to={"/"} replace />;
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
}

export default PrivateRoutes;
