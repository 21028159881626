import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useDigiSubLogic } from "./useDigiSubLogic";
import SubscribeComponent from "../../components/Subscription/SubscribeComponent";
import Content from "../../components/Content/Content";
import OTP from "../../components/Subscription/OTP";
import digiImg from "../../assets/images/digiBanner.png";

function DigiSub() {
  const {
    digikeyword,
    serviceInfo,
    serviceResponse,
    amount,
    submitfetchResponse,
    handlers,
    state,
    contentResponse,
  } = useDigiSubLogic();

  //
  const { register, errors } = state;
  const { sisLoading, sisError, sdata, serror, sstatus } = submitfetchResponse;

  const { handleSubmit, onSubmit } = handlers;
  //
  if (!serviceInfo?.keyword) {
    return (
      <div>
        <CircularProgress
          size={35}
          sx={{
            color: green[500],
            position: "absolute",
            left: "48.5%",
          }}
        />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px",
            paddingTop: "35px",
          }}
        >
          loading...
        </p>
      </div>
    );
  }
  return (
    <div
      className="digi-bkg"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <img
        src={digiImg}
        srcSet={digiImg}
        alt={serviceInfo.title}
        style={{ width: "60%", height: "60%" }}
        loading="lazy"
      />

      {!contentResponse.isPoolingDone ? (
        <OTP
          message={serviceResponse?.message}
          serviceInfo={serviceInfo}
          msisdn={serviceResponse?.msisdn}
          otp={serviceResponse?.otp}
          expiry={serviceResponse?.expiry}
          amount={amount}
        />
      ) : contentResponse.data?.data === "EXPIRED" ? (
        <SubscribeComponent
          register={register}
          errors={errors}
          serror={serror}
          sdata={sdata}
          sstatus={sstatus}
          sisLoading={sisLoading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          sisError={sisError}
          message={serviceResponse.message}
          serviceInfo={serviceInfo}
          keyword={digikeyword}
          msisdn={serviceResponse.msisdn}
          amount={amount}
        />
      ) : (
        <Content
          data={contentResponse.data?.data || []}
          keyword={digikeyword}
          msisdn={serviceResponse.msisdn}
        />
      )}
    </div>
  );
}

export default DigiSub;
