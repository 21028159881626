import { Button, CircularProgress, Typography } from "@mui/material";
import { NumberInput } from "../Form/FormsInputs";
import { green } from "@mui/material/colors";

function SubscribeComponent({
  register,
  errors,
  serror,
  sdata,
  handleSubmit,
  onSubmit,
  sisError,
  message,
  sstatus,
  sisLoading,
  serviceInfo,
  amount,
}) {
  console.log({ amount });
  console.log({ amount });
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <h1>Subscribing to {serviceInfo?.title}</h1>
      <p>{serviceInfo?.description.replace("{{amount}}", amount)}</p>
      {message}
      <NumberInput
        register={register}
        name="number"
        label="Mobile No*"
        placeholder="e.g 055XXXXXXX"
        error={errors?.number}
        autoFocus
      />
      {sisError && (
        <Typography variant="caption" color={"red"} gutterBottom align="center">
          <h1>{serror.message}</h1>
          <h2>{serror.response?.data?.data}</h2>
        </Typography>
      )}
      {sstatus === "success" && (
        <Typography variant="caption" gutterBottom align="center">
          <h2>{JSON.stringify(sdata.data?.data)}</h2>
        </Typography>
      )}
      <Button
        onClick={handleSubmit((values) => onSubmit(values, serviceInfo))}
        disabled={sisLoading}
      >
        Subscribe
        {sisLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              left: "49%",
            }}
          />
        )}
      </Button>
    </div>
  );
}

export default SubscribeComponent;
