import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Subscibe from "./pages/Subscribe/Subscribe";
import Service from "./pages/Service/Service";
import Digifish from "./pages/Digifish/Digifish";
import DigiSub from "./pages/DigiSub/DigiSub";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/digifish3/:digikeyword" element={<Digifish />} />
      <Route path="/subscribe/digifish3/:digikeyword" element={<DigiSub />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/service" element={<Service />} />
        <Route path="/subscribe/:keyword" element={<Subscibe />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
