import { useEffect } from "react";
import { usePost } from "../../hooks/usePost";
import { setAuthCookie, setKeyCookie } from "../../utils/auth";
import { isAuthTokenExpired } from "../../utils/utilities";
import { useParams } from "react-router-dom";
import { URLS } from "../../utils/constants/urls";

export default function useDigifish() {
  const { digikeyword } = useParams();
  const url = URLS.CALLBACKURL;

  const { status, accessKey } = isAuthTokenExpired();

  const onErrorCall = (error) => {
    console.log({ error });
  };

  const onSuccessFulCall = (data) => {
    setAuthCookie(`Bearer ${data?.data?.data?.authToken?.accessToken}`);
    setKeyCookie(data?.data?.data?.accessKey);
  };

  const {
    mutate,
    isLoading: isLoginLoading,
    isError: isLoginError,
    data: loginRespData,
    error: loginRespError,
  } = usePost(onSuccessFulCall, onErrorCall);

  //
  useEffect(() => {
    document.title = "Digifish HTML5 Games - Demo";
  }, []);
  //
  useEffect(() => {
    if (status === true) {
      mutate({
        url: ``,
        data: { username: "nalouser", password: "password@1234" },
        method: "post",
      });
      return;
    }
    const navLink = `http://nhe.nalovas.com/?key=${accessKey}&keyword=${digikeyword}&url=${url}/subscribe/digifish3/${digikeyword}&opt=0`;
    window.location.href = navLink;
  }, [status, accessKey, digikeyword, url]);

  return {
    loginInfo: { isLoginError, isLoginLoading, loginRespData, loginRespError },
  };
}
